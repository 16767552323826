import React from 'react'
import '../App.css';
import popReviews from '../reviews/PopReviews.json'

function Pop(){
    let reviews = popReviews.map((r) => {
        return (
            <div key={r.title}>
                <h1>{r.title} by {r.artist}</h1>
                <p>{r.date}</p>
                <p>{r.text}</p>
                <hr></hr>
            </div>
        )
    }) 
  return(
    <div className='reviews'>
      <h1>Pop Artists</h1>
      <div className='review-container'>
        {reviews}
      </div>
    </div>
  )
}

export default Pop;