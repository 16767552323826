import '../App.css';

function GetReviewed(){
  return(
    <div className="music-page">
      <h1>Do you want a review?</h1>
      <p>Our mission is to provide low-cost reviews to artists. While we can't review everything, we intend to review as much as we can.</p>
      <p>Please contact us at flipsidesymfinity@gmail.com or our our X/Twitter acccount</p>
      <p>Reviews are payable via Paypal/Venmo for $10. We also accept crypto payments in $SHIB for $7 worth of SHIB. Just cover any gas fees and pay $7 worth of SHIB at current price.</p>
    </div>
  )
}

export default GetReviewed;