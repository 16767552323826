import React from 'react';
import { Route, Switch } from "react-router-dom";
import Home from './components/Home'
import Nav from './components/Nav'
import Footer from './components/Footer'
import Metal from './containers/Metal'
import Rock from './containers/Rock'
import Pop from './containers/Pop'
import GetReviewed from './components/GetReviewed';
import About from './components/About';
import './App.css';

function App() {
  return (
    <div className="App">
      <Nav />
      <Switch>
      <Route exact path='/about' component={About} />
        <Route exact path='/get_reviewed' component={GetReviewed} />
        <Route exact path='/pop' component={Pop} />
        <Route exact path='/rock' component={Rock} />
        <Route exact path='/metal' component={Metal} />
        <Route exact path='/' component={Home} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
