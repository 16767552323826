import React from 'react'
import '../App.css';
import metalReviews from '../reviews/MetalReviews.json'

function Metal(){

    let reviews = metalReviews.map((r) => {
        return (
            <div key={r.title}>
                <h1>{r.title} by {r.artist}</h1>
                <p>{r.date}</p>
                <p>{r.text}</p>
                <hr></hr>
            </div>
        )
    }) 
    return(
        <div className='reviews'>
        <h1>Metal Artists</h1>
        <div className='review-container'>
            {reviews}
        </div>
        </div>
    )
}

export default Metal;