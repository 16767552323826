import React from 'react'
import '../App.css';
import recentReviews from '../reviews/RecentReviews.json'

function Home(){
  let reviews = recentReviews.map((r) => {
    return (
        <div key={r.title}>
            <h1>{r.title} by {r.artist}</h1>
            <p>{r.date}</p>
            <p>{r.text}</p>
            <hr></hr>
        </div>
    )
}) 
  return(
    <div className='home'>
      <h1>Welcome to Songreview.org</h1>
      <p>Bringing you song reviews in Metal, Rock and Pop!</p>
      <div>
        <p>Recent Reviews</p>
        <div className='review-container'>
            {reviews}
        </div>
      </div>
    </div>
  )
}

export default Home;
